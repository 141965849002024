<template>
  <main-wrapper detail footer tool-background-color="#409EFF" tool-color="#FFF" class="page__detail">
    <template slot="tool">
      <el-button type="primary" class="flat ipx-0 body-1 el-button--white" @click="$router.go(-1)" v-ripple>
        <i class="el-icon-back im-1"></i>
      </el-button>
      <span class="sub-title ipx-1">{{pageMode===ADD ? '新增设备' : '修改设备'}}</span>
    </template>

    <div class="v-wrapper">
      <div class="v-body" v-loading="!mountedFlag">
        <template>
          <div class="detail__main clearfix">

            <div class="card-block">
              <p class="block__title">设备信息</p>
              <div class="block__body">
                <el-form ref="dForm" :rules="ruleDevice" :model="device" label-width="80px">
                  <el-form-item label="所属用户" prop="HoldName">
                    <el-input v-model="device.HoldName" class="v-input" placeholder="请选择所属用户" readonly></el-input>
                  </el-form-item>
                  <el-form-item label="设备ID" prop="SIM2">
                    <el-input v-model="device.SIM2" class="v-input"></el-input>
                  </el-form-item>
                  <el-form-item label="SIM卡号" prop="SIM">
                    <el-input v-model="device.SIM" class="v-input"></el-input>
                  </el-form-item>
                  <el-form-item label="设备型号" prop="ObjectType">
                    <el-select v-model="device.ObjectType" placeholder="请选择设备型号">
                      <el-option v-for="item in objectTypeList" :key="item.ItemID" :label="item.ItemName" :value="item.ItemID" @click.native="() => device.ObjectTypeName = item.ItemName">
                        <span style="float: left">{{ item.ItemName }}</span>
                        <span style="float: right; color: #8492a6; font-size: 13px; margin-left: 20px;">{{ item.Remark }}</span>
                      </el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item label="接线类型" prop="HasLine">
                    <el-radio-group v-model="device.HasLine">
                      <el-radio :label="true">有线</el-radio>
                      <el-radio :label="false">无线</el-radio>
                    </el-radio-group>
                  </el-form-item>
                  <el-form-item label="备注" prop="Remark">
                    <el-input type="textarea" v-model="device.Remark"></el-input>
                  </el-form-item>
                  <el-form-item>
                    <el-button type="primary" @click="okHandle">保存</el-button>
                    <el-button @click="$router.go(-1)">取消</el-button>
                  </el-form-item>
                </el-form>
              </div>
            </div>

            <div class="card-block" v-if="false">
              <p class="block__title">
                车辆信息
              </p>
              <div class="block__body">
                <el-form ref="form" :model="device" label-width="80px">
                  <el-form-item label="选择车辆">
                    <el-select v-model="device.VehicleName" placeholder="请选择绑定车辆">
                      <el-option label="区域一" value="shanghai"></el-option>
                      <el-option label="区域二" value="beijing"></el-option>
                    </el-select>
                  </el-form-item>
                </el-form>
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>

    <template slot="footer" v-if="pageMode==ADD">
      <el-button type="primary" @click="okHandle">立即增加</el-button>
      <el-button @click="$router.go(-1)">取消</el-button>
    </template>
    <template slot="footer" v-else-if="pageMode==MODIFY">
      <el-button @click="$router.go(-1)">返回</el-button>
    </template>
  </main-wrapper>
</template>

<script>
import * as API from '@/api/device'
import mixin from './mixin-detail'
import TreeUser from '@/views/common/tree-user'
import { QueryDict } from '@/api/common'
import { Modify as ModifyDeviceAPI, Add as AddDeviceAPI } from '@/api/device'

const ADD = 'ADD'
const MODIFY = 'MODIFY'
export default {
  props: {
    itemId: {
      type: String,
      default: null
    }
  },
  components: {
    TreeUser
  },
  mixins: [mixin],
  data() {
    return {
      ADD,
      MODIFY,
      objectTypeList: [],
      device: {
        SIM: null,
        SIM2: null,
        ObjectType: null,
        HasLine: false,
        Remark: null,
        HoldID: null,
        HoldName: null,
        VehicleID: null
      },

      ruleDevice: {
        HoldName: [ {required: true,message:'请选择所属用户',trigger: 'blur'} ],
        SIM: [ {required: true,message:'请输入SIM卡号码',trigger: 'blur'} ],
        SIM2: [ {required: true,message:'请输入设备ID',trigger: 'blur'} ],
        ObjectType: [ {required: true,message:'请选择设备型号',trigger: 'change'} ],
        HasLine: [ {required: true,message:'请选择接线类型',trigger: 'change'} ],
      },

    }
  },
  created() {
    QueryDict(1, 100, '', '', [], 58).then(ret => {
      this.objectTypeList = ret.data.list.slice()
    })
  },
  mounted() {
    if (this.pageMode == ADD) {
      this.mountedFlag = true
      this.device.HoldID = this.loginInfo.HoldID
      this.device.HoldName = this.loginInfo.HoldName

    } else if (this.pageMode == MODIFY) {
      this.fetchData()
    }
  },
  computed: {
    pageMode() {
      return this.itemId === null ? ADD : MODIFY
    },
  },
  methods: {
    onSubmit() {
      console.log('submit!');
    },
    fetchData() {
      API.QueryDeviceInfo(this.itemId, this.currentHoldId).then(ret => {
        this.device = Object.assign(this.device, ret.data.device, {
          HoldName: ret.data.holdInfo.Name
        })
        this.mountedFlag = true
      })
    },
    valideVehicle(formName) {
      const promise = new Promise((resolve, reject) => {
        this.$refs[formName].validate(valid => {
          if (valid) {
            resolve(true)
          } else {
            reject(false)
          }
        })
      })
      return promise
    },
    async okHandle() {

      let valid
      try {
        valid = await this.valideVehicle('dForm')

      } catch (e) {
        valid = false
      }

      if (!valid) {
        return
      }

      if (this.pageMode == ADD) {
        AddDeviceAPI(this.device).then(ret => {
          if (ret.data.errCode) {
            this.print('error', ret.data.errMsg)
            return
          }
          this.$emit('refresh')
          this.$router.go(-1)
        })
      } else {
        ModifyDeviceAPI(this.device).then(ret => {
          if (ret.data.errCode) {
            this.print('error', ret.data.errMsg)
            return
          }
          this.$emit('refresh')
          this.$router.go(-1)
        })
      }

    },
    treeClick(holdID, name) {
      this.device.HoldID = holdID
      this.device.HoldName = name
    },
  }
}
</script>

<style lang="scss" scoped>
.title,.sub-title  {
  color: inherit;
}
.v-input {
  max-width: 215px;
}
.detail__main {
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
  .card-block {
    margin: 12px auto 0;
    box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12) !important;

    .block__title {
      line-height: 38px;
      height: 38px;
      padding: 0 16px;
      border-bottom: 1px solid #DCDFE6;
      color: #303133;
    }
    .block__body {
      padding: 12px 24px;
    }
  }
}

@media screen and (min-width: 1272px) {
  .detail__main {
    //max-width: 1296px;
    .card-block {
      width: 600px;
      margin: 12px 0 0 24px;
    }
  }
}

.v-wrapper {
  display: flex;
  align-items: stretch;
  height: 100%;
  .v-nav {
    width: 250px;
    flex-shrink: 0;
    overflow-y: auto;
    position: relative;
  }
  .v-body {
    flex-grow: 1;
    height: 100%;
    overflow-y: auto;
  }
}
  .v-body {
    height: 100%;
    overflow-y: auto;
  }
</style>
